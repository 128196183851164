<template>
  <q-separator spaced v-if="i" />
  <q-card flat>
    <q-item>
      <q-item-section avatar>
        <img :src="company.logo_foto_link" style="width: 120px" />
      </q-item-section>
      <q-item-section>
        <q-badge
          rounded
          :color="
            company.partners[0].approved == 1
              ? 'green-4'
              : verifyAccessToSendDocs(company.partners[0])
              ? 'yellow-7'
              : 'blue-4'
          "
          class="q-pa-sm"
        >
          <q-icon
            class="q-mr-sm"
            :name="company.partners[0].approved == 1 ? 'done' : 'pending'"
          />
          <span
          style="font-size: 8pt;"
            v-html="
              company.partners[0].approved == 1
                ? $t('dashboard.flip.cards.approved')
                : verifyAccessToSendDocs(company.partners[0])
                ? $t('dashboard.flip.cards.send_docs')
                : $t('dashboard.flip.cards.pending')
            "
          />
        </q-badge>
      </q-item-section>
    </q-item>
    <q-card-actions align="around">
      <q-btn
        color="black"
        class="full-width q-mb-sm"
        :loading="in_request"
        @click="login(company.slug)"
        :disabled="verifyAbbleAccess(company.partners[0])"
        :label="$t('dashboard.flip.cards.access')"
      />
      <ModalFlipPartnerSubscribe
        :data="company"
        v-model="modal"
        :showActions="false"
        :show="true"
        :label="$t('dashboard.flip.cards.details')"
        :color="'blue-8'"
      />
    </q-card-actions>
  </q-card>
</template>

<script>
import ModalFlipPartnerSubscribe from "../pages/home/components/ModalFlipPartnerSubscribe.vue";
import { createNamespacedHelpers } from "vuex-composition-helpers";
import { defineComponent, ref } from "vue";

const authNamespace = createNamespacedHelpers("auth");

export default defineComponent({
  name: "card-my-companies",

  components: { ModalFlipPartnerSubscribe },

  props: {
    company: {
      type: Array,
      required: true,
    },
  },

  setup() {
    const modal = ref(false),
      in_request = ref(false);

    const { signInByDashboard } = authNamespace.useActions([
      "signInByDashboard",
    ]);

    const login = (slug) => {
      in_request.value = true;
      signInByDashboard({
        slug,
        path: `/${slug}/redirect/dashboard`,
      });
    };

    const verifyAbbleAccess = (partner) => {
      if (partner.parent.parameters.length) {
        if (partner.approved == 1) return false;
        return !(partner.has_pend == null || partner.has_pend == 1);
      } else return partner.approved == 1 ? false : true;
    };
    const verifyAccessToSendDocs = (partner) => {
      if (partner.has_pend == null || partner.has_pend == 1)
        return (
          partner.approved == null &&
          (partner.has_pend == null || partner.has_pend == 1) &&
          partner.parent.parameters.length
        );
      else return false;
    };

    return {
      modal,
      in_request,
      login,
      verifyAbbleAccess,
      verifyAccessToSendDocs,
    };
  },
});
</script>

<style scoped>
.my-card {
  cursor: pointer;
  border: 1px solid rgb(84, 84, 84);
}
</style>
