<template>
  <q-btn round dense flat :ripple="false">
    <div class="text-weight-medium text-capitalize q-ml-sm q-mr-sm">
      <q-icon name="dashboard_customize" class="q-mr-sm" />
      <template v-if="$q.screen.width > 900">
        {{ $t("Minhas marcas") }}
        <q-icon name="expand_more" class="q-ml-sm" />
      </template>
    </div>
    <q-menu
      class="q-mt-lg full-width"
      transition-show="jump-down"
      transition-hide="jump-up"
      min-width="260px"
      max-width="260px"
      anchor="bottom middle"
      self="top middle"
    >
      <q-list
        bordered
        padding
        class="q-mb-md"
        v-if="filterCompanies(getCompaniesRegisteredList).length > 0"
      >
        <template
          v-for="(el, i) in filterCompanies(getCompaniesRegisteredList)"
          :key="i"
        >
          <q-separator class="q-ma-xs" v-if="i" />
          <cardMyCompanies :company="el" />
        </template>
      </q-list>
      <div class="text-center bg-grey-4 q-pa-xl q-ma-md default-rounded" v-else>
        Você ainda não se cadastrou em nenhum programa.
      </div>
    </q-menu>
  </q-btn>
</template>

<script>
import cardMyCompanies from "./card-my-companies.vue";
import { createNamespacedHelpers } from "vuex-composition-helpers";
import { defineComponent, ref } from "vue";

const homeNamespace = createNamespacedHelpers("home"),
  partnerNamespaced = createNamespacedHelpers("partner");

export default defineComponent({
  name: "list-my-companies",

  components: {
    cardMyCompanies,
  },

  setup() {
    const in_request = ref(false);

    const { getCompaniesRegisteredList } = homeNamespace.useGetters([
        "getCompaniesRegisteredList",
      ]),
      { partner } = partnerNamespaced.useGetters({ partner: "getPartner" });

    const filterCompanies = (companies) =>
      companies.filter((company) => company.slug != partner.value.slug);

    return {
      getCompaniesRegisteredList,
      partner,
      in_request,
      filterCompanies,
    };
  },
});
</script>
